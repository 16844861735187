:root {
  --drawer-width: 237px;
  --drawer-closed-width: 57px;
}

.mobile-drawer {
  width: 290px;
  background: var(--background-taf);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
}

.desktop-drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
}

.desktop-drawer.MuiDrawer-paperAnchorDockedLeft {
  border-right: none !important;
  position: static;
}

.drawer-open {
  width: var(--drawer-width);
  max-width: var(--drawer-width);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
  border-right: 1px solid #FFFFFF;
  transition: width 0.2s ease-in;
  background-color: #FFFFFF !important;
}

.drawer-close {
  width: var(--drawer-closed-width);
  max-width: var(--drawer-closed-width);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
  transition: width 0.2s ease-out;
  overflow-x: hidden;
}

/* Main content offset */
.main-content {
  margin-left: var(--drawer-width);
  transition: margin-left 0.2s ease-in-out;
}

.main-content-collapsed {
  margin-left: var(--drawer-closed-width);
  transition: margin-left 0.2s ease-in-out;
}

.logout-container {
  margin-top: auto;
  margin-bottom: 30px;
  padding: 16px;
  display: flex;
  justify-content: center;
  width: var(--drawer-width);
  background-color: #f5f5f5 !important; 
  margin-left: 0%;
}

.logout-button-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #858585 !important;
  background: none;
padding: 0px;
}
.logout-button-nav:hover {
  background: #043E71 !important;
  color: #EFEFEF !important;
  border-radius: 9px;
  padding: 16px;
  width: 100%;
  transition: all 0.3s ease;
}

.logout-button-icon {
  margin-right: 8px;
}

.navbar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #FFFFFF; 
}

.logo-container {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  width: 100%;
  max-height: 60px;
  object-fit: contain;
}

.content-nav {
  padding-top: 6px;
  width: 100%;
}

.nav-item {
  display: flex;
  align-items: center;
  color: #0358AC;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  width: 100%;
  padding: 12px 16px;
  margin-top: -20px;
}

.nav-item.active {
  background-color: #00A1E036;
  color: #032E61;
}

.sub-nav-item {
  margin-left: 20%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: #676c70;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
}

.sub-nav-item:hover {
  color: #032E61;
}

@media (max-width: 960px) {
  .main-content {
    margin-left: 0;
  }
}